import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableColumnDate from 'components/reusable/TableColumnDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Table, Typography } from 'antd';

const { Text } = Typography;

const CompanyBoardMembers = ({ boardMembersData }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const boardMembersColumns = [
    {
      title: t('professionalReport.basicData.boardMembers.date'),
      dataIndex: 'dateFrom',
      render: TableColumnDate,
      key: 'dateForm',
      sorter: (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.boardMembers.name'),
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.boardMembers.position'),
      dataIndex: 'position',
      key: 'position',
      sorter: (a, b) => a.position.localeCompare(b.position),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <p>
          <Link
            to={`/search/company?manager=${record?.fullName.split(',')[0]}&managerCreditinfoId=${record?.creditinfoId}`}
            target="_blank"
          >
            <Text type="info">{t('global.managedCompaniesSearch')}</Text>
          </Link>
        </p>
      ),
    },
  ];

  return (
    <Table
      bordered
      dataSource={boardMembersData}
      columns={isUiHidden ? boardMembersColumns.slice(0, boardMembersColumns.length - 1) : boardMembersColumns}
      scroll={{ x: 'max-content' }}
      pagination={{ defaultPageSize: 10, showSizeChanger: true }}
      rowKey={({ fullName, dateFrom, position }) => `boardMember-${fullName}-${dateFrom}-${position}`}
      showSorterTooltip={false}
    />
  );
};

export default CompanyBoardMembers;
