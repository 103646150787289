import { fetchFromApi, optionsForPostRequest, optionsForPutRequest } from './';

const changePhoneNumber = data => fetchFromApi('/users/change-phone-number', optionsForPostRequest(data));

const changePassword = passwordData => fetchFromApi('/users/change-password', optionsForPostRequest(passwordData));

const resetPassword = data => fetchFromApi('/users/reset-password', optionsForPostRequest(data));

const acceptTermsAndCoditions = value =>
  fetchFromApi(`/users/change-terms-and-conditions/${value}`, optionsForPutRequest());

export default {
  changePhoneNumber,
  changePassword,
  resetPassword,
  acceptTermsAndCoditions,
};
