import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

import { getSession } from 'store/entities/user/thunks';
import Login from 'views/Login';
import routes from 'components/Router/routes';
import { languageCodes, reCaptchaKey } from 'constants/general';

const finalRoutes = checkPathAvailable =>
  routes.map(
    route =>
      checkPathAvailable(route) && (
        <Route key={route.path} exact={route.exact} path={route.path}>
          <route.component />
        </Route>
      )
  );

const Router = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { isAuthenticated, features, claims, session } = useSelector(state => state.user);
  const location = useLocation();
  const isPdfReport = location?.pathname?.includes('/pdf-print');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(getSession());
    }
  }, []);

  const checkIfUnAuthenticatedPathAvailable = useCallback(
    route => {
      const { keys, authenticate } = route;

      if (authenticate) {
        return false;
      }

      if (keys && features) {
        return keys.some(key => features.includes(key));
      }

      return true;
    },
    [features]
  );

  const checkIfAuthenticatedPathAvailable = useCallback(
    route => {
      const { keys, unregisteredOnly } = route;

      if (unregisteredOnly) {
        return false;
      }

      if (keys && claims && features) {
        return (
          keys.some(key => features.includes(key)) ||
          keys.some(key => claims.filter(item => item.key === key && item.value).length > 0)
        );
      }

      return true;
    },
    [claims, features]
  );

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={reCaptchaKey}
      language={languageCodes[i18n.language]}
      scriptProps={{
        async: true,
        defer: true,
      }}
      useRecaptchaNet
    >
      <Switch>
        {isAuthenticated && <Redirect from="/" to="/search/company" exact />}
        {isAuthenticated && <Redirect from="/search/company/:companyId/result" to="/" exact />}
        <Redirect from="/administration" to="/administration/debtors-administration" exact />
        {isAuthenticated && <Redirect from="/otherProducts" to="/monitorings" exact />}
        {isAuthenticated && (
          <Redirect from="/monitorings/credit-monitoring" to="/monitorings/credit-monitoring/legal-entities" exact />
        )}
        {isAuthenticated && (
          <Redirect from="/monitorings/web-monitoring" to="/monitorings/web-monitoring/legal-entities" exact />
        )}
        {isAuthenticated &&
          !isPdfReport &&
          (session?.isPasswordChangeMandatory || (session && !session.isTermsAndConditionsAccepted)) && (
            <Redirect from="/search" to="/terms-and-conditions" exact />
          )}
        {isAuthenticated &&
          !isPdfReport &&
          (session?.isPasswordChangeMandatory || (session && !session.isTermsAndConditionsAccepted)) && (
            <Redirect from="/search/company" to="/terms-and-conditions" exact />
          )}
        <Redirect from="/search" to="/search/company" exact />
        {isAuthenticated ? (
          finalRoutes(checkIfAuthenticatedPathAvailable)
        ) : (
          <>
            {finalRoutes(checkIfUnAuthenticatedPathAvailable)}
            <Route path="/" exact>
              <Login />
            </Route>
          </>
        )}
      </Switch>
    </GoogleReCaptchaProvider>
  );
};

export default Router;
