import { Trans, useTranslation } from 'react-i18next';
import { useIsMdOrLess } from 'hooks/dom/useMedia';

import { useHistory, useLocation } from 'react-router-dom';
import { Col, Typography, Row, Button, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { showErrorNotification } from '../../utils/notification';

const { Paragraph } = Typography;

const LoginOptions = ({ setLoginOption }) => {
  const { i18n, t } = useTranslation();
  const screenIsMdOrLess = useIsMdOrLess();
  const history = useHistory();
  const location = useLocation();
  const { search } = useLocation();
  const [form] = Form.useForm();

  useEffect(() => {
    location.search && handleSearch(location.search);
  }, [location.search]);

  const handleSearch = values => {
    const { searchValue } = values;
    if (searchValue) {
      if (searchValue.length < 2) {
        showErrorNotification(t('search.input.toShort'), 5);
        return false;
      }
      if (search) {
        history.push(`/search/company${search}&search=${searchValue}`);
      } else {
        history.push(`/search/company?search=${searchValue}`);
      }
    } else {
      history.push(`search/company${search}`);
    }
  };

  const getCompanyStrongestPageByLang = lang =>
    lang === 'lt-LT'
      ? 'https://lt.creditinfo.com/lt/stipriausi-lietuvoje/'
      : 'https://lt.creditinfo.com/en/strongest-in-lithuania/';

  return (
    <>
      <Row justify="start" gutter={[8, 16]}>
        <Col xs={24} sm={24} md={24} lg={0} className="login-image-small">
          <a href={getCompanyStrongestPageByLang(i18n.language)} target="_blank" rel="noopener noreferrer">
            <div
              className={`login-image
              ${i18n.language === 'en-US' ? 'login-image--en' : 'login-image--lt'}
              `}
            >
              <div className="top-text">{t('banner.top.text')}</div>
              <div className="bottom-text">{t('banner.bottom.text')}</div>
            </div>
          </a>
        </Col>
        <Paragraph className="login-info-description">{t('login.intro1')}</Paragraph>
        <Paragraph className="login-info-description">{t('login.intro2')}</Paragraph>
        <Col span={24} style={{ marginBottom: 8 }}>
          <Form form={form} name="loginSearch" onFinish={handleSearch}>
            <Form.Item name="searchValue" style={{ marginBottom: 8 }}>
              <div className="login-free-block">
                <span>
                  <Trans i18nKey={`login.option.freeInformationTitle`} components={{ bold: <strong /> }} />
                </span>
                <Input
                  className="login-block-input"
                  placeholder={t('login.search.placeholder')}
                  size="large"
                  suffix={<SearchOutlined onClick={() => form.submit()} />}
                />
              </div>
            </Form.Item>
          </Form>
          <Button
            className="btn-xl__secondary-green"
            block={screenIsMdOrLess}
            onClick={() => handleSearch(form.getFieldsValue())}
          >
            {t('login.option.buyReport')}
          </Button>
        </Col>
        <Col span={12} lg={24} xl={24} xxl={12}>
          <Button size="default" block={screenIsMdOrLess} onClick={() => setLoginOption('registered')}>
            {t('login.option.registeredUsers')}
          </Button>
        </Col>
        <Col span={12} lg={24} xl={24} xxl={12}>
          <Button
            size="default"
            block={screenIsMdOrLess}
            onClick={() => window.open('https://www.manocreditinfo.lt/', '_blank')}
          >
            {t('login.option.myCreditinfo')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default LoginOptions;
