import { fetchFromApi, optionsForGetRequest, optionsForPostRequest } from './';

const getCompanyData = (companyCode, languageCode) =>
  fetchFromApi(`/data-availability/${companyCode}/${languageCode}/report/summary`);

const getOrderIdFromCompanyBuyReport = company =>
  fetchFromApi('/data-availability-order/company', optionsForPostRequest(company));

const getOrderIdFromPersonBuyReport = person =>
  fetchFromApi('/data-availability-order/person', optionsForPostRequest(person));

const getOpayUriEncoded = (orderId, languageCode) =>
  fetchFromApi(`/opay/${orderId}?languageCode=${languageCode}`, optionsForGetRequest());

const getCompanyCredentials = filters => fetchFromApi('/data-availability/search', optionsForPostRequest(filters));

export default {
  getCompanyData,
  getOrderIdFromCompanyBuyReport,
  getOrderIdFromPersonBuyReport,
  getOpayUriEncoded,
  getCompanyCredentials,
};
