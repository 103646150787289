import TableColumnDate from '../../TableColumnDate';
import { PieChartOutlined } from '@ant-design/icons';
import { Col, Tooltip, Typography } from 'antd';
import CompanyDataCard, { CompanyDataRow } from '../../CompanyDataCard';
import CollapsibleTable from '../../CollapsibleTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../../hooks/format/useFormatDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Link } = Typography;

export const JadisInformationCard = ({ jadisInformation, jadisInformationHistory }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatDate = useFormatDate();

  const historicalJadisColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'sharedholders_historic_dateFrom',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('global.dateTo'),
      dataIndex: 'dateTo',
      key: 'sharedholders_historic_dateTo',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.shares.jadisInformation.personsShareholdersAmount'),
      dataIndex: 'personsShareholdersAmount',
      key: 'personsShareholdersAmount',
    },
    {
      title: t('professionalReport.basicData.shares.jadisInformation.companiesShareholdersAmount'),
      dataIndex: 'companiesShareholdersAmount',
      key: 'companiesShareholdersAmount',
    },
    {
      title: t('professionalReport.basicData.shares.jadisInformation.foreignCompaniesShareholdersAmount'),
      dataIndex: 'foreignCompaniesShareholdersAmount',
      key: 'foreignCompaniesShareholdersAmount',
    },
    {
      title: t('professionalReport.basicData.shares.jadisInformation.foreignPersonsShareholdersAmount'),
      dataIndex: 'foreignPersonsShareholdersAmount',
      key: 'foreignPersonsShareholdersAmount',
    },
  ];

  return (
    <CompanyDataCard
      icon={<PieChartOutlined className="icon--gray" />}
      title={t('professionalReport.basicData.shares.jadisInformation.title')}
    >
      <Col>
        <CompanyDataRow
          value={
            <Tooltip title={t('professionalReport.basicData.shares.jadisInformation.explanation')}>
              {t('professionalReport.basicData.shares.jadisInformation.numberOfShareholders')} <InfoCircleOutlined />
            </Tooltip>
          }
          extraClass="fw-600"
        />
        <CompanyDataRow
          type={t('professionalReport.basicData.shares.jadisInformation.personsShareholdersAmount')}
          value={
            jadisInformation?.personsShareholdersAmount ??
            t('professionalReport.basicData.shares.jadisInformation.noInformation')
          }
        />
        <CompanyDataRow
          type={t('professionalReport.basicData.shares.jadisInformation.companiesShareholdersAmount')}
          value={
            jadisInformation?.companiesShareholdersAmount ??
            t('professionalReport.basicData.shares.jadisInformation.noInformation')
          }
        />
        <CompanyDataRow
          type={t('professionalReport.basicData.shares.jadisInformation.foreignCompaniesShareholdersAmount')}
          value={
            jadisInformation?.foreignCompaniesShareholdersAmount ??
            t('professionalReport.basicData.shares.jadisInformation.noInformation')
          }
        />
        <CompanyDataRow
          type={t('professionalReport.basicData.shares.jadisInformation.foreignPersonsShareholdersAmount')}
          value={
            jadisInformation?.foreignPersonsShareholdersAmount ??
            t('professionalReport.basicData.shares.jadisInformation.noInformation')
          }
        />
        <p></p>
        <CompanyDataRow
          type={t('professionalReport.basicData.shares.jadisInformation.informationUpdated')}
          value={
            formatDate(jadisInformation?.updatedDate) ??
            t('professionalReport.basicData.shares.jadisInformation.noInformation')
          }
        />
        <span>
          {t('professionalReport.basicData.shares.jadisInformation.disclaimer')}
          <Link
            href="https://creativecommons.org/licenses/by/4.0/deed.lt"
            target="_blank"
            rel="noopener noreferrer"
            underline
          >
            CC BY 4.0.
          </Link>
        </span>
      </Col>
      {jadisInformationHistory?.length ? (
        <CollapsibleTable
          className="space-top-md"
          title={t('professionalReport.basicData.shares.historicalInformation.title')}
          data={jadisInformationHistory}
          columns={historicalJadisColumns}
          bordered
          pagination={false}
        />
      ) : null}
    </CompanyDataCard>
  );
};
