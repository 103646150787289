import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Affix, Col, Menu, Row, Typography } from 'antd';
import cx from 'classnames';

import Summary from 'views/ProfessionalReport/Summary';
import BasicData from 'views/ProfessionalReport/BasicData';
import CompanyRatings from 'views/ProfessionalReport/CompanyRatings';
import NegativeData from 'views/ProfessionalReport/NegativeData';
import FinancialData from 'views/ProfessionalReport/FinancialData';
import VisibilitySensorBlock from 'components/reusable/report/VisibilitySensorBlock';
import { scrollToSection } from 'utils/dom';
import { getProfessionalReportPdf } from '../../../store/entities/professionalReport/thunks';
import { useParams } from 'react-router-dom';
import { DownloadPdfBtn } from '../../../components/reusable/report/buttons';
import { setActivePdfModal } from '../../../store/entities/professionalReport';

const { Title } = Typography;

const FullReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('summary');
  const [selectedTab, setSelectedTab] = useState('');
  const [menuAffixed, setMenuAffixed] = useState(false);

  const { companyId } = useParams();
  const { orderId } = useSelector(state => state.professionalReport);
  const { hasSession } = useSelector(state => state.user);

  const menuClassName = cx('tabs', {
    'tabs--affixed': menuAffixed,
  });

  const pdfBtnStyle = {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  };

  const menuTabs = ['summary', 'basicData', 'companyRatings', 'negativeData', 'financialData'];

  const handleMenuClick = e => {
    e.domEvent.preventDefault();
    e.domEvent.stopPropagation();
    scrollToSection(e.key);
    setSelectedTab(e.key);
  };

  const handleTabChange = (tabName, scrollDirection) => {
    if (tabName === selectedTab && tabName === activeTab) return;
    if (scrollDirection === 'down') {
      setActiveTab(tabName);
    } else if (tabName === 'summary') {
      setActiveTab(tabName);
    } else {
      setActiveTab(menuTabs[menuTabs.indexOf(tabName) - 1]);
    }
  };

  return (
    <Row className="professional-full-report">
      <Col span={24}>
        <Affix offsetTop={0} onChange={affixed => setMenuAffixed(affixed)} className="affix-style">
          {hasSession && (
            <Col xs={{ span: 24 }} lg={{ span: 0 }}>
              <DownloadPdfBtn
                fetcher={() =>
                  dispatch(
                    getProfessionalReportPdf({
                      companyCode: companyId,
                      orderId,
                    })
                  )
                }
                reportType="professional"
                style={{ marginLeft: 5, position: 'relative', float: 'right' }}
              />
              <DownloadPdfBtn
                fetcher={() => dispatch(setActivePdfModal(true))}
                text="global.customPdf"
                reportType="professional"
                style={{ position: 'relative', float: 'right' }}
              />
            </Col>
          )}
          <Col xs={14} sm={10} md={12} lg={13} xl={15} xxl={24}>
            <Menu
              onClick={handleMenuClick}
              selectedKeys={activeTab}
              mode="horizontal"
              className={menuClassName}
              selectable
            >
              {menuTabs.map(tab => (
                <Menu.Item key={tab} onClick={() => handleTabChange(tab)}>
                  {t(`professionalReport.tab.${tab}`)}
                </Menu.Item>
              ))}
            </Menu>
          </Col>
          {hasSession && (
            <Col xs={{ span: 0 }} lg={{ span: 12 }} style={pdfBtnStyle}>
              <DownloadPdfBtn
                fetcher={() =>
                  dispatch(
                    getProfessionalReportPdf({
                      companyCode: companyId,
                      orderId,
                    })
                  )
                }
                reportType="professional"
                style={{ marginLeft: 5, position: 'relative', float: 'right' }}
              />
              <DownloadPdfBtn
                fetcher={() => dispatch(setActivePdfModal(true))}
                text="global.customPdf"
                reportType="professional"
                style={{ position: 'relative', float: 'right' }}
              />
            </Col>
          )}{' '}
        </Affix>
      </Col>
      <Col style={{ marginTop: 20 }} span={24}>
        <VisibilitySensorBlock id="summary" key="summary" onChange={handleTabChange}>
          <>
            <Title level={5} className="header">
              01. {t('professionalReport.tab.summary')}
            </Title>
            <Summary />
          </>
        </VisibilitySensorBlock>
        <VisibilitySensorBlock id="basicData" key="basicData" onChange={handleTabChange}>
          <>
            <Title level={5} className="header space-top-md">
              02. {t('professionalReport.tab.basicData')}
            </Title>
            <BasicData isFullReport />
          </>
        </VisibilitySensorBlock>
        <VisibilitySensorBlock id="companyRatings" key="companyRatings" onChange={handleTabChange}>
          <>
            <Title level={5} className="header space-top-md">
              03. {t('professionalReport.tab.companyRatings')}
            </Title>
            <CompanyRatings />
          </>
        </VisibilitySensorBlock>
        <VisibilitySensorBlock id="negativeData" key="negativeData" onChange={handleTabChange}>
          <>
            <Title level={5} className="header space-top-md">
              04. {t('professionalReport.tab.negativeData')}
            </Title>
            <NegativeData />
          </>
        </VisibilitySensorBlock>
        <VisibilitySensorBlock id="financialData" key="financialData" onChange={handleTabChange}>
          <>
            <Title level={5} className="header space-top-md">
              05. {t('professionalReport.tab.financialData')}
            </Title>
            <FinancialData />
          </>
        </VisibilitySensorBlock>
      </Col>
    </Row>
  );
};

export default FullReport;
