import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import CollapsibleTable from 'components/reusable/CollapsibleTable';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import TableColumnDate from 'components/reusable/TableColumnDate';

import { Card, Row, Table } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const Licences = ({ licences, briefPdf }) => {
  const [customReport, setCustomReport] = useState(null);

  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const { customPdfValues } = useSelector(state => state.professionalReport);

  useEffect(() => {
    if (customPdfValues && 'Licenses' in customPdfValues) {
      setCustomReport(customPdfValues['Licenses']);
    }
  }, [customPdfValues]);

  const licenceActiveColumns = [
    {
      title: t('professionalReport.basicData.licences.registered'),
      dataIndex: 'validFrom',
      key: 'validFrom',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.validFrom) - new Date(a.validFrom),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.licences.activity'),
      dataIndex: 'activityName',
      key: 'activityName',
      sorter: (a, b) => a.activityName.localeCompare(b.activityName),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.licences.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ['ascend', 'descend'],
      width: 100,
    },
  ];

  const licenceExpiredColumns = [
    {
      title: t('professionalReport.basicData.licences.registered'),
      dataIndex: 'validFrom',
      key: 'validFrom',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.validFrom) - new Date(a.validFrom),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.licences.unregistered'),
      dataIndex: 'validUntil',
      key: 'validUntil',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.validUntil) - new Date(a.validUntil),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.licences.activity'),
      dataIndex: 'activityName',
      key: 'activityName',
      sorter: (a, b) => a.activityName.localeCompare(b.activityName),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.licences.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ['ascend', 'descend'],
      width: 100,
    },
  ];

  return (
    <>
      <CompanyDataCard
        icon={<IdcardOutlined className="icon--gray" />}
        title={t('professionalReport.basicData.licences.title')}
        disclaimer={licences.active.length && briefPdf ? t('professionalReport.basicData.licences.disclaimer') : null}
      >
        {licences.active.length ? (
          <>
            {!briefPdf && (
              <CompanyDataRow type={t('professionalReport.basicData.licences.count')} value={licences.active.length} />
            )}

            {customPdfValues && !customReport ? (
              <Row>
                <Card className="card-transparent space-vertical-sm">
                  {t('professionalReport.basicData.licences.customDisclaimer')}
                </Card>
              </Row>
            ) : (
              <>
                <Table
                  className="space-top-md"
                  rowKey="id"
                  bordered
                  dataSource={briefPdf ? licences.active.slice(0, 10) : licences.active}
                  columns={licenceActiveColumns}
                  scroll={isUiHidden ? false : { x: 'max-content' }}
                  pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
                  showSorterTooltip={false}
                />

                <CollapsibleTable
                  title={t('professionalReport.basicData.licences.license_historical_info')}
                  data={briefPdf ? licences.expired.slice(0, 10) : licences.expired}
                  columns={licenceExpiredColumns}
                  bordered
                  scroll={isUiHidden ? false : { x: 'max-content' }}
                  pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
                  showSorterTooltip={false}
                />

                {briefPdf && (
                  <Row>
                    <Card className="card-transparent space-vertical-sm">
                      {t('professionalReport.basicData.licences.brief.disclaimer')}
                    </Card>
                  </Row>
                )}
              </>
            )}
          </>
        ) : (
          <Card className="card-transparent space-vertical-sm">
            {t('professionalReport.basicData.licences.noInformation')}
          </Card>
        )}
      </CompanyDataCard>
    </>
  );
};

export default Licences;
