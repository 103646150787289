export const checkDateValidity = date => {
  const dateString = new Date(date);

  return !isNaN(dateString);
};

export const isOlderThanXYear = (dateString, xYear) => {
  const givenDate = new Date(dateString);
  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - (xYear || 1));
  return givenDate < twoYearsAgo;
};
