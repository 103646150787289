import React from 'react';

import ComposedChart from 'components/reusable/graphs/ComposedChart';
import { DATE_FORMAT_YEAR_ONLY } from 'hooks/format/useFormatDate';

const RevenueChart = ({ chartData, incomeType = 'area', profitType = 'area', income = false, profit = false }) => (
  <>
    {!income && !profit && (
      <ComposedChart
        id="companyProfit"
        series={[
          {
            type: incomeType,
            dataKey: 'income',
            stroke: '#C40F11',
            key: 'income',
          },
          {
            type: profitType,
            dataKey: 'profit',
            stroke: '#0C9CB2',
            key: 'profit',
            labelUnder: true,
          },
        ]}
        activeDefs
        data={chartData}
        xAxisFormat={DATE_FORMAT_YEAR_ONLY}
        lazyLoaded
        useValueFormatter
        hideLabels
      />
    )}
    {income && (
      <ComposedChart
        showLegend={false}
        id="companyIncome"
        series={[
          {
            type: incomeType,
            dataKey: 'income',
            stroke: '#C40F11',
            key: 'income',
          },
        ]}
        activeDefs
        data={chartData}
        xAxisFormat={DATE_FORMAT_YEAR_ONLY}
        tooltipLabelFormat={DATE_FORMAT_YEAR_ONLY}
        lazyLoaded
        useValueFormatter
        hideLabels
      />
    )}
    {profit && (
      <ComposedChart
        showLegend={false}
        id="companyProfit"
        series={[
          {
            type: profitType,
            dataKey: 'profit',
            stroke: '#0C9CB2',
            key: 'profit',
          },
        ]}
        activeDefs
        data={chartData}
        xAxisFormat={DATE_FORMAT_YEAR_ONLY}
        lazyLoaded
        useValueFormatter
        hideLabels
      />
    )}
  </>
);

export default RevenueChart;
