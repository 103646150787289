import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import useFormatDate, { DATE_FORMAT_LONG } from 'hooks/format/useFormatDate';

import { Card, Col, Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import CollapsibleTable from '../../CollapsibleTable';
import TableColumnDate from '../../TableColumnDate';

const { Text } = Typography;

const CompanyManager = ({ managerData, managersHistory }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const isUiHidden = useIsUiHidden();

  const checkCompanyBirthDate = date => {
    if (!date) return '-';

    if (Date.parse(date) <= 0 || isNaN(Date.parse(date))) return '-';

    return formatDate(date);
  };

  const columns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      render: TableColumnDate,
      key: 'dateFrom',
      sorter: (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('global.dateUntil'),
      dataIndex: 'dateUntil',
      render: TableColumnDate,
      key: 'dateUntil',
      sorter: (a, b) => new Date(b.dateUntil) - new Date(a.dateUntil),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.managersChangeHistory.companyManager'),
      dataIndex: 'managerFullName',
      key: 'managerFullName',
      sorter: (a, b) => a.managerFullName.localeCompare(b.managerFullName),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <>
          <p>
            <Link
              to={`/search/company?manager=${record.managerFullName.split(',')[0]}&managerCreditinfoId=${
                record.managerCreditinfoId
              }`}
              target="_blank"
            >
              <Text type="info">{t('global.managedCompaniesSearch')}</Text>
            </Link>
          </p>
        </>
      ),
    },
  ];

  return (
    <CompanyDataCard
      icon={<UserOutlined className="icon--gray" />}
      title={t('professionalReport.basicData.managersChangeHistory.title')}
    >
      {managerData?.managerFullName ? (
        <>
          <CompanyDataRow
            type={t('professionalReport.basicData.managersChangeHistory.manager')}
            value={
              <div className="space-between">
                <span>{managerData.managerFullName}</span>
                {!isUiHidden ? (
                  <Link
                    to={`/search/company?manager=${managerData.managerFullName.split(',')[0]}&managerCreditinfoId=${
                      managerData.managerCreditinfoId
                    }`}
                    target="_blank"
                  >
                    <Text type="info">{t('global.managedCompaniesSearch')}</Text>
                  </Link>
                ) : (
                  false
                )}
              </div>
            }
          />
          {managerData?.restrictedDateFrom && managerData?.restrictedDateTo && (
            <Row>
              <Col className="color-secondary">
                {t('professionalReport.basicData.managersChangeHistory.restriction', {
                  restrictedDateFrom: formatDate(managerData?.restrictedDateFrom, DATE_FORMAT_LONG),
                  restrictedDateTo: formatDate(managerData?.restrictedDateTo, DATE_FORMAT_LONG),
                })}
              </Col>
            </Row>
          )}
          <CompanyDataRow
            type={t('professionalReport.basicData.managersChangeHistory.birthDate')}
            value={checkCompanyBirthDate(managerData?.birthDate)}
          />
          <CompanyDataRow
            type={t('professionalReport.basicData.managersChangeHistory.dateFrom')}
            value={managerData?.dateFrom ? formatDate(managerData?.dateFrom) : ''}
          />
          <Row className="space-top-md">
            {managersHistory?.length > 0 && (
              <CollapsibleTable
                title={t('professionalReport.basicData.managersChangeHistory.historicalInformation.title')}
                data={managersHistory}
                columns={isUiHidden ? columns.slice(0, columns.length - 1) : columns}
                bordered
                showSorterTooltip={false}
              />
            )}
          </Row>
        </>
      ) : (
        <Card className="card-transparent space-vertical-sm">
          {t('professionalReport.basicData.managersChangeHistory.noInformation')}
        </Card>
      )}
    </CompanyDataCard>
  );
};

export default CompanyManager;
