import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const IndividualOrderForm = ({ vatBillRequired }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} className="space-top-md">
          <Form.Item
            label={t('companyResult.buyReport.individualInformationForm.name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('companyResult.buyReport.individualInformationForm.name.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.individualInformationForm.name.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.individualInformationForm.email')}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: t('companyResult.buyReport.individualInformationForm.email.required'),
              },
            ]}
            required
          >
            <Input placeholder={t('companyResult.buyReport.individualInformationForm.email.placeholder')} />
          </Form.Item>
        </Col>
      </Row>
      {vatBillRequired ? (
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('companyResult.buyReport.individualInformationForm.address.required'),
                },
              ]}
              label={t('companyResult.buyReport.individualInformationForm.address')}
              name="address"
              required
            >
              <Input placeholder={t('companyResult.buyReport.individualInformationForm.address.placeholder')} />
            </Form.Item>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default IndividualOrderForm;
