import React, { useState, useEffect } from 'react';
import SearchForm from './SearchForm';
import SearchResultsTable from './SearchResultsTable';

export const SearchBlock = ({ isSpiderwebOpen, setIsSpiderwebOpenForm, setIsSpiderwebOpenResults }) => {
  const [selectedCountry, setSelectedCountry] = useState('LT');
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  useEffect(() => {
    isSpiderwebOpen && setShowMoreFilters(false);
  }, [isSpiderwebOpen]);

  const handleFilterChange = () => {
    setShowMoreFilters(!showMoreFilters);
  };

  return (
    <>
      <SearchForm
        setIsSpiderwebOpen={setIsSpiderwebOpenForm}
        setSelectedCountry={setSelectedCountry}
        showMoreFilters={showMoreFilters}
        handleFilterChange={handleFilterChange}
        selectedCountry={selectedCountry}
      />
      <SearchResultsTable setIsSpiderwebOpen={setIsSpiderwebOpenResults} selectedCountry={selectedCountry} />
    </>
  );
};
