import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import useBreadcrumbTitles from 'hooks/report/useBreadcrumbTitles';
import { basicInfo } from 'store/entities/companyFinancialReport/selectors/basicDataSelectors';
import useFormatDate from '../../hooks/format/useFormatDate';

const FinancialStatementsBreadcrumb = props => {
  const { location } = props;
  const { basicData } = useSelector(state => state.companyFinancialReport);
  const basicInformation = useSelector(basicInfo);
  const breadcrumbTitles = useBreadcrumbTitles(basicInformation);
  const formatDate = useFormatDate();
  const breadcrumbsArr = location.pathname.split('/').slice(1);
  const lastElOrStatementId = breadcrumbsArr.pop();
  const statement = basicData?.basicInformation.find(
    item => item.financialStatementId.toString() === lastElOrStatementId
  );

  if (!basicData?.companyName) return null;
  document.title = 'KBS ' + basicData?.companyName;

  const renderBreadcrumbItem = item => {
    switch (item) {
      case basicData?.companyCode.toString():
        return basicData.companyName;
      case lastElOrStatementId:
        return `${formatDate(statement?.financialYearBegin)} - ${formatDate(statement?.financialYearEnd)}`;
      default:
        return item;
    }
  };

  return breadcrumbTitles.map((item, i) => (
    <Breadcrumb.Item key={item} href={`/${breadcrumbsArr.slice(0, i + 1).join('/')}`}>
      {renderBreadcrumbItem(item)}
    </Breadcrumb.Item>
  ));
};

export default FinancialStatementsBreadcrumb;
