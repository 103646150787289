import { Card, Typography, List, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PasswordChangeModal from './PasswordChangeModal';
import { acceptTermsAndCoditions } from 'store/entities/user/thunks';
import { setPasswordChangeModalActive } from '../../store/entities/user';
import useRedirect from '../../hooks/navigation/useRedirect';
import { useEffect } from 'react';

const { Title, Paragraph } = Typography;

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { session } = useSelector(state => state.user);
  const redirect = useRedirect();

  const handleTermsAndConditions = async () => {
    const termsAndConditionsRequest = await dispatch(acceptTermsAndCoditions(true));
    if (acceptTermsAndCoditions.fulfilled.match(termsAndConditionsRequest)) {
      await redirect('/search');
    }
  };

  useEffect(() => {
    if (session?.isPasswordChangeMandatory) {
      dispatch(setPasswordChangeModalActive(true));
    }
  }, [session]);

  return (
    <div className="container">
      <PasswordChangeModal />
      <Card className="card-simple space-vertical-lg has-shadow">
        <div className="justify-content-center">
          <span style={{ fontWeight: 600, fontSize: '1.5rem' }}>{t('termsAndConditions.title')}</span>
        </div>
        <Typography>
          <section>
            <Title level={4} style={{ marginTop: '0.4em' }}>
              {t('termsAndConditions.P1.title')}
            </Title>
            <Title level={3} style={{ marginTop: '0.1em' }}>
              {t('termsAndConditions.P1.subtitle')}
            </Title>
            <Paragraph>{t('termsAndConditions.P1.text')}</Paragraph>
          </section>
          <section>
            <Title level={4}>{t('termsAndConditions.P2.title')}</Title>
            <Paragraph>{t('termsAndConditions.P2.text')}</Paragraph>
          </section>
          <section>
            <Title level={4}>{t('termsAndConditions.P3.title')}</Title>
            <Paragraph>{t('termsAndConditions.P3.text1')}</Paragraph>
            <Paragraph>{t('termsAndConditions.P3.text2')}</Paragraph>
            <Paragraph>{t('termsAndConditions.P3.text3')}</Paragraph>
          </section>
          <section>
            <Title level={4}>{t('termsAndConditions.P4.title')}</Title>
            <Paragraph>{t('termsAndConditions.P4.text1')}</Paragraph>
            <ul className="list-style-none">
              {[
                t('termsAndConditions.P4.text1.li1'),
                t('termsAndConditions.P4.text1.li2'),
                t('termsAndConditions.P4.text1.li3'),
                t('termsAndConditions.P4.text1.li4'),
                t('termsAndConditions.P4.text1.li5'),
                t('termsAndConditions.P4.text1.li6'),
              ].map((item, index) => (
                <li key={index} style={{ margin: '0.2em' }}>
                  <span style={{ fontWeight: 600, marginRight: '0.5em' }}>
                    {String.fromCharCode(97 + index)}) {/* Alphabetical index */}
                  </span>
                  {item}
                </li>
              ))}
            </ul>
            <Paragraph>{t('termsAndConditions.P4.text2')}</Paragraph>
            <Paragraph>{t('termsAndConditions.P4.text3')}</Paragraph>
          </section>
          <section>
            <Title level={4}>{t('termsAndConditions.P5.title')}</Title>
            <Paragraph>{t('termsAndConditions.P5.text')}</Paragraph>
            <ul className="list-style-none">
              {[
                t('termsAndConditions.P5.text.li1'),
                t('termsAndConditions.P5.text.li2'),
                t('termsAndConditions.P5.text.li3'),
                t('termsAndConditions.P5.text.li4'),
                t('termsAndConditions.P5.text.li5'),
                t('termsAndConditions.P5.text.li6'),
              ].map((item, index) => (
                <li key={index} style={{ margin: '0.2em' }}>
                  <span style={{ fontWeight: 'bold', marginRight: '0.5em' }}>-</span>
                  {item}
                </li>
              ))}
            </ul>
          </section>
          <section>
            <Title level={4}>{t('termsAndConditions.P6.title')}</Title>
            <Paragraph>{t('termsAndConditions.P6.text1')}</Paragraph>
            <Paragraph>{t('termsAndConditions.P6.text2')}</Paragraph>
            <Paragraph>{t('termsAndConditions.P6.text3')}</Paragraph>
          </section>
        </Typography>
        {/*{session?.isTermsAndCoditionsAccepted && (*/}
        <div className="justify-content-center">
          <Button className="btn-secondary" onClick={handleTermsAndConditions}>
            {t('global.agree')}
          </Button>
        </div>
        {/*)}*/}
      </Card>
    </div>
  );
};

export { TermsAndConditions };
