import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Select, Space, Button, Input, Card } from 'antd';
import { SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import useQueryParams from 'hooks/navigation/useQueryParams';
import { getCompanies, getDistricts } from 'store/entities/company/thunks';
import { setSelectedCompany } from 'store/entities/company';

import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import { showErrorNotification } from '../../../utils/notification';

const { Option } = Select;

const CompanySearchForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const queryParams = useQueryParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const previousPayload = useRef(null);

  const { isAuthenticated } = useSelector(state => state.user);
  const { districts, searchResults, getCompaniesPending, getDistrictsPending } = useSelector(state => state.company);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const managerSearch = queryParams.get('manager');
  const addressSearch = queryParams.get('address');
  const municipalitySearch = queryParams.get('municipality');
  const telephoneSearch = queryParams.get('telephone');
  const managerCreditinfoIdSearch = queryParams.get('managerCreditinfoId');
  const companySearch = queryParams.get('name');

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getDistricts());
    }

    if (searchResults.length > 0) {
      dispatch(getCompanies(form.getFieldsValue(true)));
    }
  }, [lang]);

  useEffect(() => {
    if (queryParams.size) {
      const searchPayload = {
        ...(managerSearch && { manager: managerSearch }),
        ...(addressSearch && { address: addressSearch }),
        ...(municipalitySearch && { municipality: municipalitySearch }),
        ...(telephoneSearch && { telephone: telephoneSearch }),
        ...(companySearch && { name: companySearch }),
        ...(managerCreditinfoIdSearch && { managerCreditinfoId: managerCreditinfoIdSearch }),
      };

      if (JSON.stringify(previousPayload.current) !== JSON.stringify(searchPayload)) {
        previousPayload.current = searchPayload;
        dispatch(getCompanies(searchPayload));
      }

      form.setFieldsValue(searchPayload);
    } else {
      form.resetFields();
    }
  }, [queryParams]);

  const handleSubmit = values => {
    const params = new URLSearchParams();

    if (values.name) {
      if (values?.name?.length < 2) {
        showErrorNotification(t('search.input.toShort'), 5);
        return false;
      }
      params.append('name', values.name);
    }
    if (values.manager) {
      params.append('manager', values.manager);
    }
    if (values.address) {
      params.append('address', values.address);
    }
    if (values.municipality) {
      params.append('municipality', values.municipality);
    }
    if (values.telephone) {
      params.append('telephone', values.telephone);
    }

    dispatch(setSelectedCompany(null));
    history.push(`/search/company?${params.toString()}`);
  };

  return (
    <Form form={form} hideRequiredMark={true} layout="vertical" name="companySearchFilters" onFinish={handleSubmit}>
      <LoadingSpinner size="large" spinning={getCompaniesPending}>
        <Card className="card-simple space-vertical-lg has-shadow">
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            gutter={!showMoreFilters && [16, 16]}
          >
            <Col xs={24} md={24} lg={24} xl={12} xxl={15}>
              <Form.Item name="name">
                <Input placeholder={t('companySearch.companyName')} />
              </Form.Item>
              {showMoreFilters && (
                <Row gutter={[16, 16]} style={{ rowGap: 0 }}>
                  <Col xs={24} md={24} lg={24} xl={24}>
                    <Form.Item name="manager">
                      <Input placeholder={t('companySearch.manager')} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12} xl={24}>
                    <Form.Item name="address">
                      <Input placeholder={t('companySearch.address')} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item name="municipality">
                      <Select
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        loading={getDistrictsPending}
                        placeholder={t('companySearch.municipality')}
                      >
                        {districts.map(({ name }) => (
                          <Option key={name} value={name}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item name="telephone">
                      <Input placeholder={t('companySearch.phone')} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <Row style={{ alignItems: 'baseline' }} align="bottom">
                {isAuthenticated && (
                  <Form.Item
                    style={{
                      marginTop: '0px',
                      marginBottom: showMoreFilters ? '24px' : 0,
                    }}
                  >
                    <Button
                      type="link"
                      onClick={() => setShowMoreFilters(!showMoreFilters)}
                      icon={showMoreFilters ? <MinusOutlined /> : <PlusOutlined />}
                    >
                      {showMoreFilters ? t('companySearch.lessOptions') : t('companySearch.moreOptions')}
                    </Button>
                  </Form.Item>
                )}
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                    {t('companySearch.search')}
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Card>
      </LoadingSpinner>
    </Form>
  );
};

export default CompanySearchForm;
