import Search from 'views/Search';
import ProfessionalReport from 'views/ProfessionalReport';
import Profile from 'views/Profile';
import PageNotFound from 'views/PageNotFound';

import DefaultBreadcrumb from 'components/Breadcrumbs/DefaultBreadcrumb';
import ProfessionalReportPdf from 'views/ProfessionalReport/ProfessionalReportPdf';
import IndividualProfessionalReportPdf from 'views/IndividualReport/IndividualProfessionalReportPdf';
import CompanySearchResult from 'views/CompanySearchResult';
import ConsultationRegistration from 'views/ConsultationRegistration';
import IndividualReport from 'views/IndividualReport';
import OtherProducts from 'views/OtherProducts';
import CreditMonitoring from 'views/OtherProducts/CreditMonitoring';
import Administration from 'views/Administration';
import DebtManagement from 'views/Administration/DebtManagement';
import AdministrationBreadcrumb from 'components/Breadcrumbs/AdministrationBreadcrumb';
import ProfessionalReportBreadcrumb from 'components/Breadcrumbs/ProfessionalReportBreadcrumb';
import CompanySearchResultBreadcrumb from 'components/Breadcrumbs/CompanySearchResultBreadcrumb';
import IndividualReportBreadcrumb from 'components/Breadcrumbs/IndividualReportBreadcrumb';
import CumulativeBreadcrumb from 'components/Breadcrumbs/CumulativeBreadcrumb';
import StartReport from 'views/StartReport';
import StartReportBreadcrumb from 'components/Breadcrumbs/StartReportBreadcrumb';
import FinancialStatements from 'views/FinancialStatements';
import FinancialStatementsBreadcrumb from 'components/Breadcrumbs/FinancialStatementsBreadcrumb';
import CompanyCreditLimit from 'views/CompanyCreditLimit';
import CompanyCreditLimitBreadcrumb from 'components/Breadcrumbs/CompanyCreditLimitBreadcrumb';
import PrescoreReport from 'views/PrescoreReport';
import PrescoreReportBreadcrumb from 'components/Breadcrumbs/PrescoreReportBreadcrumb';
import MainInformation from 'views/MainInformation';
import MainInformationBreadcrumb from 'components/Breadcrumbs/MainInformationBreadcrumb';
import ReportPurchaseStatus from 'views/CompanySearchResult/components/BuyReport/ReportPurchaseStatus';
import WebMonitoring from 'views/OtherProducts/WebMonitoring';
import CompanyCreditLimitPdf from 'views/CompanyCreditLimit/CompanyCreditLimitPdf';
import MainInformationPdf from 'views/MainInformation/MainInformationPdf';
import FinancialStatementsPdf from 'views/FinancialStatements/FinancialStatementsPdf';
import FinancialStatementDetailedData from 'views/FinancialStatements/FinancialStatementDetailedData';
import Basic from '../../views/Search/CompanySearch/Basic';
import SpiderwebView from '../../views/OtherProducts/Spiderweb/OldSpiderwebMap/SpiderwebView';
import SpiderwebReportPdf from '../../views/OtherProducts/Spiderweb/OldSpiderwebMap/SpiderwebReportPdf';
import StartReportPdf from '../../views/StartReport/StartReportPdf';
import PrescoreReportPdf from '../../views/PrescoreReport/Data/PrescoreReportPdf';
import Login from '../../views/Login';
import Spiderweb from 'views/OtherProducts/Spiderweb';
import { TermsAndConditions } from '../../views/TermsAndConditions';

const routes = [
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    exact: true,
    breadcrumb: DefaultBreadcrumb,
    authenticate: false,
  },
  {
    path: '/search/company',
    component: Search,
    exact: true,
    breadcrumb: DefaultBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company',
    component: Search,
    exact: true,
    breadcrumb: DefaultBreadcrumb,
    authenticate: false,
    unregisteredOnly: true,
    keys: ['webui_data_availability'],
  },
  {
    path: '/search/company/:companyId',
    component: Basic,
    exact: true,
    breadcrumb: CompanySearchResultBreadcrumb,
    authenticate: true,
    keys: ['webui_data_availability'],
  },
  {
    path: '/search/foreignCompany',
    component: Search,
    exact: true,
    breadcrumb: DefaultBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/professional-report',
    component: ProfessionalReport,
    exact: true,
    breadcrumb: ProfessionalReportBreadcrumb,
    authenticate: true,
    keys: ['/product/companyprofessionalreport/'],
  },
  {
    path: '/search/company/:companyId/result',
    component: CompanySearchResult,
    exact: true,
    breadcrumb: CompanySearchResultBreadcrumb,
    authenticate: false,
    keys: ['webui_data_availability'],
  },
  {
    path: '/search/company/consultation',
    component: ConsultationRegistration,
    exact: true,
    breadcrumb: DefaultBreadcrumb,
    authenticate: false,
    keys: ['webui_data_availability'],
  },
  {
    path: '/search/company/:companyId/professional-report/pdf-print',
    component: ProfessionalReportPdf,
    exact: true,
    authenticate: true,
    keys: ['/product/companyprofessionalreport/'],
  },
  {
    path: '/search/company/:companyId/start-report',
    component: StartReport,
    exact: true,
    breadcrumb: StartReportBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/start-report/pdf-print',
    component: StartReportPdf,
    exact: true,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/financial-statements',
    component: FinancialStatements,
    exact: true,
    breadcrumb: FinancialStatementsBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/financial-statements/:statementId/pdf-print',
    component: FinancialStatementsPdf,
    exact: true,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/financial-statements/:statementId',
    component: FinancialStatementDetailedData,
    exact: true,
    breadcrumb: FinancialStatementsBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/financial-statements/:statementId/pdf-print',
    component: FinancialStatementsPdf,
    exact: true,
    breadcrumb: FinancialStatementsBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/company-credit-limit',
    component: CompanyCreditLimit,
    exact: true,
    breadcrumb: CompanyCreditLimitBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/company-credit-limit/pdf-print',
    component: CompanyCreditLimitPdf,
    exact: true,
    breadcrumb: CompanyCreditLimitBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/prescore-report',
    component: PrescoreReport,
    exact: true,
    breadcrumb: PrescoreReportBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/prescore-report/pdf-print',
    component: PrescoreReportPdf,
    exact: true,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/main-information',
    component: MainInformation,
    exact: true,
    breadcrumb: MainInformationBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/company/:companyId/main-information/pdf-print',
    component: MainInformationPdf,
    exact: true,
    breadcrumb: MainInformationBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/individual',
    component: Search,
    exact: true,
    breadcrumb: DefaultBreadcrumb,
    authenticate: true,
  },
  //TODO: Add inner page for individual person
  {
    path: '/search/individual/:individualId',
    component: IndividualReport,
    exact: true,
    breadcrumb: IndividualReportBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/individual/:individualId/payment-history',
    component: IndividualReport,
    exact: true,
    breadcrumb: IndividualReportBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/individual/:individualId/open-debts',
    component: IndividualReport,
    exact: true,
    breadcrumb: IndividualReportBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/individual/:individualId/persons-register',
    component: IndividualReport,
    exact: true,
    breadcrumb: IndividualReportBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/individual/:individualId/professional-report',
    component: IndividualReport,
    exact: true,
    breadcrumb: IndividualReportBreadcrumb,
    authenticate: true,
  },
  {
    path: '/search/individual/:individualId/professional-report/pdf-print',
    component: IndividualProfessionalReportPdf,
    exact: true,
    breadcrumb: IndividualReportBreadcrumb,
    authenticate: true,
  },
  {
    path: '/monitorings',
    component: OtherProducts,
    exact: true,
    breadcrumb: CumulativeBreadcrumb,
    authenticate: true,
    keys: ['webui_otherproducts_monitoring'],
  },
  {
    path: '/spiderweb',
    component: Spiderweb,
    exact: true,
    breadcrumb: CumulativeBreadcrumb,
    authenticate: true,
    keys: ['webui_otherproducts_spiderweb'],
  },
  {
    path: '/spiderweb/:companyOrPersonId',
    component: SpiderwebView,
    exact: true,
    breadcrumb: CumulativeBreadcrumb,
    authenticate: false,
    keys: ['webui_otherproducts_spiderweb'],
  },
  {
    path: '/spiderweb/:companyOrPersonId/pdf-print',
    component: SpiderwebReportPdf,
    exact: true,
    authenticate: true,
    keys: ['webui_otherproducts_spiderweb'],
  },
  {
    path: '/monitorings/credit-monitoring/legal-entities',
    component: CreditMonitoring,
    exact: true,
    breadcrumb: CumulativeBreadcrumb,
    authenticate: true,
    keys: ['webui_otherproducts_monitoring'],
  },
  {
    path: '/monitorings/credit-monitoring/individual-person',
    component: CreditMonitoring,
    exact: true,
    breadcrumb: CumulativeBreadcrumb,
    authenticate: true,
    keys: ['webui_otherproducts_monitoring'],
  },
  {
    path: '/monitorings/credit-monitoring/file-upload',
    component: CreditMonitoring,
    exact: true,
    breadcrumb: CumulativeBreadcrumb,
    authenticate: true,
    keys: ['webui_otherproducts_monitoring'],
  },
  {
    path: '/monitorings/web-monitoring/legal-entities',
    component: WebMonitoring,
    exact: true,
    breadcrumb: CumulativeBreadcrumb,
    authenticate: true,
    keys: ['webui_otherproducts_monitoring'],
  },
  {
    path: '/monitorings/web-monitoring/individual-person',
    component: WebMonitoring,
    exact: true,
    breadcrumb: CumulativeBreadcrumb,
    authenticate: true,
    keys: ['webui_otherproducts_monitoring'],
  },
  {
    path: '/administration/debtors-administration',
    component: Administration,
    exact: true,
    breadcrumb: AdministrationBreadcrumb,
    authenticate: true,
  },
  {
    path: '/administration/debtors-administration/debt-management',
    component: DebtManagement,
    exact: true,
    breadcrumb: AdministrationBreadcrumb,
    authenticate: true,
  },
  {
    path: '/report-purchase/:status',
    component: ReportPurchaseStatus,
    exact: false,
    authenticate: false,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    breadcrumb: DefaultBreadcrumb,
    authenticate: true,
  },
  {
    path: '/login',
    component: Login,
    authenticate: false,
  },
  {
    path: '*',
    component: PageNotFound,
    authenticate: true,
  },
];

export default routes;
