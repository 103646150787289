import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, Input, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import useQueryParams from 'hooks/navigation/useQueryParams';
import { getCompaniesUnregistered } from 'store/entities/company/thunks';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { setSelectedCompany } from 'store/entities/company';
import { useHistory } from 'react-router-dom';
import { showErrorNotification } from '../../../utils/notification';

const CompanySearchForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { getCompaniesPending } = useSelector(state => state.company);
  const companySearch = queryParams.get('search');
  const [loading, setLoading] = useState(true);

  const handleRecaptcha = async name => {
    try {
      const token = await executeRecaptcha('search');
      await dispatch(getCompaniesUnregistered({ name, token }));
    } catch (error) {
      console.error('Recaptcha error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companySearch && executeRecaptcha) {
      form.setFieldsValue({ name: companySearch });
      handleRecaptcha(companySearch);
    } else {
      setLoading(false);
    }
  }, [executeRecaptcha, companySearch]);

  const handleSubmit = values => {
    if (values?.name?.length < 2) {
      showErrorNotification(t('search.input.toShort'), 5);
      return false;
    }
    const params = new URLSearchParams({ search: values.name });
    history.push(`/search/company?${params.toString()}`);
    dispatch(setSelectedCompany(null));
    setLoading(true);
    handleRecaptcha(values.name);
  };

  return (
    <Form form={form} layout="vertical" name="companySearchFilters" onFinish={handleSubmit}>
      <LoadingSpinner size="large" spinning={loading || getCompaniesPending}>
        <Col span={24} className="search-banner">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <div className={`login-image ${i18n.language === 'en-US' ? 'login-image--en' : 'login-image--lt'}`}>
              <div className="top-text">{t('banner.top.text')}</div>
              <div className="bottom-text">{t('banner.bottom.text')}</div>
            </div>
          </a>
        </Col>
        <div
          style={{
            background: '#0a7a001a',
            padding: '20px 20px 0 20px',
            borderRadius: 15,
            border: '1px solid #e2e8f0',
            textAlign: 'center',
            marginBottom: 24,
          }}
        >
          <span>
            <Trans i18nKey="login.option.freeInformationTitle" components={{ bold: <strong /> }} />
          </span>
          <Form.Item name="name">
            <Input
              className="login-block-input"
              placeholder={t('login.search.placeholder')}
              size="large"
              suffix={<SearchOutlined onClick={() => form.submit()} />}
            />
          </Form.Item>
        </div>
      </LoadingSpinner>
    </Form>
  );
};

export default CompanySearchForm;
