import React from 'react';
import { useTranslation } from 'react-i18next';

import CompanyDataCard from 'components/reusable/CompanyDataCard';
import CollapsibleTable from 'components/reusable/CollapsibleTable';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import TableColumnDate from 'components/reusable/TableColumnDate';

import { Col, Row, Table } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

const Licences = ({ licences }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const licenceColumns = [
    {
      title: t('professionalReport.basicData.licences.registered'),
      dataIndex: 'validFrom',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.licences.activity'),
      dataIndex: 'activityName',
    },
    {
      title: t('professionalReport.basicData.licences.type'),
      dataIndex: 'type',
      width: 100,
    },
  ];
  const licenceExpiredColumns = [
    {
      title: t('professionalReport.basicData.licences.registered'),
      dataIndex: 'validFrom',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.licences.unregistered'),
      dataIndex: 'validUntil',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.licences.activity'),
      dataIndex: 'activityName',
    },
    {
      title: t('professionalReport.basicData.licences.type'),
      dataIndex: 'type',
      width: 100,
    },
  ];

  return (
    <>
      <CompanyDataCard
        icon={<IdcardOutlined className="icon--gray" />}
        title={t('professionalReport.basicData.licences.title')}
        disclaimer={licences.active.length > 50 ? t('professionalReport.basicData.licences.last') : false}
      >
        <Table
          bordered
          dataSource={licences.active.length > 50 ? licences.active.slice(0, 50) : licences.active}
          columns={licenceColumns}
          scroll={isUiHidden ? false : { x: 'max-content' }}
          pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
          showSorterTooltip={false}
        />
        <Row>
          {licences?.expired?.length > 0 && (
            <CollapsibleTable
              disclaimer={
                licences.expired.length > 50 ? t('professionalReport.basicData.licences.historical.last') : false
              }
              className="space-top-md"
              title={t('professionalReport.basicData.licences.license_historical_info')}
              data={licences.expired.length > 50 ? licences.expired.slice(0, 50) : licences.expired}
              columns={licenceExpiredColumns}
              bordered
              scroll={isUiHidden ? false : { x: 'max-content' }}
              pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
              showSorterTooltip={false}
            />
          )}
        </Row>
      </CompanyDataCard>
    </>
  );
};

export default Licences;
