import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Breadcrumb } from 'antd';

const ProfessionalReportBreadcrumb = props => {
  const { match, location } = props;

  const { t } = useTranslation();
  const { summary, orderDetails } = useSelector(state => state.professionalReport);
  const breadcrumbsArr = location.pathname.split('/').slice(1);

  if (!orderDetails?.companyTitle) return null;
  document.title = 'KBS ' + orderDetails?.companyTitle;

  return location.pathname
    .split('/')
    .slice(1)
    .map((urlPart, i) => (
      <Breadcrumb.Item key={`breadcrumbItem-${i}`} href={`/${breadcrumbsArr.slice(0, i + 1).join('/')}`}>
        {urlPart === match.params.companyId
          ? parseInt(urlPart) === summary?.strongest?.companyCode
            ? orderDetails?.companyTitle
            : match.params.companyId
          : t(`header.breadcrumb.${urlPart}`)}
      </Breadcrumb.Item>
    ));
};

export default ProfessionalReportBreadcrumb;
