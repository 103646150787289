import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { unregisteredReportData } from '../../../../store/entities/companySearchResult/selectors/unregisteredReportSelector';
import { getCompanyCredentials } from '../../../../store/entities/companySearchResult/thunks';
import { buyReportData } from '../../../../store/entities/companySearchResult/selectors/buyReportSelector';

const CompanyOrderForm = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { unregisteredCompanySearch } = useSelector(unregisteredReportData);
  const { companyBuyerInformation } = useSelector(buyReportData);
  const [companyCode, setCompanyCode] = useState('');
  const [showMoreFields, setShowMoreFields] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const resetFormFields = () => {
    setShowMoreFields(true);
    form.setFieldsValue({ name: '', address: '', payCode: '' });
  };

  const handleRecaptchaVerification = useCallback(
    async ({ name }) => {
      const symbolRegex = /[^0-9]/g;
      const hasSymbols = symbolRegex.test(name);

      if (hasSymbols) {
        resetFormFields();
        return;
      }

      if (name.length > 7 && name.length < 10) {
        setShowMoreFields(false);
        try {
          const token = await executeRecaptcha('search');
          dispatch(getCompanyCredentials({ name, token }));
        } catch {
          resetFormFields();
        }
      } else {
        resetFormFields();
      }
    },
    [executeRecaptcha, dispatch, form]
  );

  const handleCompanyCodeChange = useMemo(
    () =>
      debounce(value => {
        handleRecaptchaVerification({ name: value });
      }, 500),
    [handleRecaptchaVerification]
  );

  const onCompanyCodeChange = e => {
    const value = e.target.value.trim();
    setCompanyCode(value);

    if (value.length) {
      handleCompanyCodeChange(value);
    }
  };

  useEffect(() => {
    if (unregisteredCompanySearch?.length) {
      const updatedCompanyInfo = {
        name: unregisteredCompanySearch[0].name || '',
        address: unregisteredCompanySearch[0].address || '',
        payCode: unregisteredCompanySearch[0].vatCode || '',
      };
      form.setFieldsValue(updatedCompanyInfo);
    } else if (unregisteredCompanySearch && !unregisteredCompanySearch.length) {
      resetFormFields();
    }
  }, [unregisteredCompanySearch]);

  useEffect(() => {
    if (companyBuyerInformation?.name) {
      const updatedCompanyInfo = {
        name: companyBuyerInformation?.name || '',
        address: companyBuyerInformation?.address || '',
        payCode: companyBuyerInformation?.payCode || '',
      };
      form.setFieldsValue(updatedCompanyInfo);
    }
  }, []);

  const handleInputChange = (key, value) => {
    const updatedCompanyInfo = {
      ...form.getFieldsValue(),
      [key]: value,
    };
    form.setFieldsValue(updatedCompanyInfo);
  };

  return (
    <>
      <Row gutter={[32]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.email')}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: t('companyResult.buyReport.companyInformationForm.email.required'),
              },
            ]}
          >
            <Input placeholder={t('companyResult.buyReport.companyInformationForm.email.placeholder')} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[32]}>
        <Col xs={24}>
          <Form.Item
            label={t('companyResult.buyReport.companyInformationForm.companyCode')}
            name="companyCode"
            rules={[
              {
                required: true,
                message: t('companyResult.buyReport.companyInformationForm.companyCode.required'),
              },
            ]}
          >
            <Input
              placeholder={t('companyResult.buyReport.companyInformationForm.companyCode.placeholder')}
              value={companyCode}
              onChange={onCompanyCodeChange}
            />
          </Form.Item>
        </Col>
      </Row>

      {(unregisteredCompanySearch || showMoreFields) && (
        <>
          <Row gutter={[32]}>
            <Col xs={24} className="space-top-md">
              <Form.Item
                label={t('companyResult.buyReport.companyInformationForm.name')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('companyResult.buyReport.companyInformationForm.name.placeholder'),
                  },
                ]}
              >
                <Input
                  placeholder={t('companyResult.buyReport.companyInformationForm.name.placeholder')}
                  onChange={e => handleInputChange('name', e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[32]}>
            <Col xs={24}>
              <Form.Item
                label={t('companyResult.buyReport.companyInformationForm.address')}
                name="address"
                rules={[
                  {
                    required: true,
                    message: t('companyResult.buyReport.companyInformationForm.address.required'),
                  },
                ]}
              >
                <Input
                  placeholder={t('companyResult.buyReport.companyInformationForm.address.placeholder')}
                  onChange={e => handleInputChange('address', e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[32]}>
            <Col xs={24}>
              <Form.Item
                label={t('companyResult.buyReport.companyInformationForm.payCode')}
                name="payCode"
                rules={[
                  {
                    message: t('companyResult.buyReport.companyInformationForm.payCode.required'),
                  },
                ]}
              >
                <Input
                  placeholder={t('companyResult.buyReport.companyInformationForm.payCode.placeholder')}
                  onChange={e => handleInputChange('payCode', e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CompanyOrderForm;
