import { createSlice } from '@reduxjs/toolkit';
import {
  changeLanguage,
  getSession,
  signIn,
  signOut,
  changePhoneNumber,
  changePassword,
  getFeatures,
  acceptTermsAndCoditions,
  changeMandatoryPassword,
} from './thunks';
import { track } from '@amplitude/analytics-browser';
import { notification } from 'antd';

const sessionSyncChannel = new BroadcastChannel('session_sync_channel');

const initialState = {
  features: null,
  session: null,
  hasSession: false,
  isAuthenticated: false,
  sessionLoading: false,
  signInPending: false,
  signOutPending: false,
  languageChangePending: false,
  phoneNumberChangePending: false,
  passwordChangePending: false,
  passwordChangeSuccess: false,
  passwordChangeModalActive: false,
  loginError: false,
  acceptTermsAndCoditionsPending: false,
};

const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setIsAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    setPasswordChangeModalActive: (state, { payload }) => {
      state.passwordChangeModalActive = payload;
      if (payload === false) state.passwordChangeSuccess = false;
    },
  },
  extraReducers: {
    [getSession.fulfilled]: (state, { payload }) => {
      state.session = payload;
      state.features = payload.features;
      state.claims = payload.claims;
      state.hasSession = true;
      state.sessionLoading = false;
    },
    [getSession.pending]: state => {
      state.sessionLoading = true;
    },
    [getSession.rejected]: state => {
      state.session = null;
      state.hasSession = false;
      state.sessionLoading = false;
    },
    [getFeatures.fulfilled]: (state, { payload }) => {
      state.features = payload.features;
      state.featuresLoading = false;
    },
    [getFeatures.pending]: state => {
      state.featuresLoading = true;
      state.features = null;
    },
    [getFeatures.rejected]: state => {
      state.featuresLoading = false;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.signInPending = false;
      sessionSyncChannel.postMessage({ type: 'LOGIN' });
      track('Signing Up');
      notification.destroy();
      localStorage.setItem('token', payload.accessToken);
      state.loginError = false;
    },
    [signIn.pending]: state => {
      state.signInPending = true;
      state.loginError = false;
    },
    [signIn.rejected]: state => {
      state.signInPending = false;
      localStorage.removeItem('token');
      state.loginError = true;
    },
    [signOut.fulfilled]: state => {
      state.signOutPending = false;
      state.session = null;
      state.hasSession = false;
      state.isAuthenticated = false;
      sessionSyncChannel.postMessage({ type: 'LOGOUT' });
      track('Signing Out');
      localStorage.removeItem('token');
    },
    [signOut.pending]: state => {
      state.signOutPending = true;
    },
    [signOut.rejected]: state => {
      state.signOutPending = false;
      state.session = null;
      state.hasSession = false;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    },
    [changeLanguage.fulfilled]: state => {
      track('Language changed');
      state.languageChangePending = false;
    },
    [changeLanguage.pending]: state => {
      state.languageChangePending = true;
    },
    [changeLanguage.rejected]: state => {
      state.languageChangePending = false;
    },
    [changePhoneNumber.fulfilled]: state => {
      state.phoneNumberChangePending = false;
    },
    [changePhoneNumber.pending]: state => {
      state.phoneNumberChangePending = true;
    },
    [changePhoneNumber.rejected]: state => {
      state.phoneNumberChangePending = false;
    },
    [changePassword.fulfilled]: state => {
      state.passwordChangeSuccess = true;
      state.passwordChangePending = false;
    },
    [changePassword.pending]: state => {
      state.passwordChangeSuccess = false;
      state.passwordChangePending = true;
    },
    [changePassword.rejected]: state => {
      state.passwordChangePending = false;
    },
    [changeMandatoryPassword.fulfilled]: state => {
      state.passwordChangeSuccess = true;
      state.session.isTermsAndConditionsAccepted = false;
      state.session.isPasswordChangeMandatory = false;
      state.passwordChangePending = false;
    },
    [changeMandatoryPassword.pending]: state => {
      state.passwordChangeSuccess = false;
      state.passwordChangePending = true;
    },
    [changeMandatoryPassword.rejected]: state => {
      state.passwordChangePending = false;
    },
    [acceptTermsAndCoditions.fulfilled]: state => {
      state.session.isTermsAndConditionsAccepted = true;
      state.acceptTermsAndCoditionsPending = false;
    },
    [acceptTermsAndCoditions.pending]: state => {
      state.acceptTermsAndCoditionsPending = true;
    },
    [acceptTermsAndCoditions.rejected]: state => {
      state.acceptTermsAndCoditionsPending = false;
    },
  },
});

export const { setPasswordChangeModalActive, setIsAuthenticated, setInitialState } = slice.actions;

export default slice.reducer;
