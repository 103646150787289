import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Row, Col, message } from 'antd';
import Modal from 'components/reusable/Modal';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';
import { ReactComponent as Success } from 'assets/img/icons/success.svg';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import { changeMandatoryPassword } from 'store/entities/user/thunks';
import { setPasswordChangeModalActive } from 'store/entities/user';
import Title from 'antd/lib/typography/Title';

const PasswordChangeModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { passwordChangePending, passwordChangeSuccess, passwordChangeModalActive } = useSelector(state => state.user);

  const [form] = Form.useForm();

  const handleSubmit = values => {
    const { currentPassword, newPassword, confirmPassword } = values;
    dispatch(
      changeMandatoryPassword({
        isPasswordChangedMandatory: true,
        currentPassword,
        newPassword,
        newPasswordRepeat: confirmPassword,
      })
    );
  };

  const closeModal = () => {
    dispatch(setPasswordChangeModalActive(false));
    form.resetFields();
  };

  return (
    <Modal
      isOpen={passwordChangeModalActive}
      setIsOpen={isOpen => dispatch(setPasswordChangeModalActive(isOpen))}
      closable={false}
      size="xs"
      keyboard={false}
    >
      {passwordChangeSuccess ? (
        <div style={{ minHeight: 165 }}>
          <Row>
            <Col span={24} className="justify-content-end">
              <Row justify="end">
                <span className="icon-btn" role="button" onClick={closeModal}>
                  <Close />
                </span>
              </Row>
            </Col>
          </Row>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 110 }}>
            <Success style={{ marginRight: '10px' }} />
            <div className="color-success">{t('passwordChange.modal.successMsg')}</div>
          </div>
        </div>
      ) : (
        <LoadingSpinner size="large" spinning={passwordChangePending}>
          <Form form={form} onFinish={handleSubmit}>
            <Row>
              <Col
                span={24}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}
              >
                <Title level={4} style={{ textAlign: 'center' }}>
                  {t('passwordChange.modal.title')}
                </Title>
              </Col>
            </Row>
            <Form.Item
              name="currentPassword"
              rules={[{ required: true, message: t('passwordChange.modal.noOldPassword') }]}
            >
              <Input.Password placeholder={t('passwordChange.modal.currentPassword')} />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[
                { required: true, message: t('passwordChange.modal.noNewPassword') },
                { min: 12, message: t('passwordChange.modal.passwordTooShort') },
              ]}
            >
              <Input.Password placeholder={t('passwordChange.modal.newPassword')} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={['newPassword']}
              rules={[
                { required: true, message: t('passwordChange.modal.noConfirmPassword') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('passwordChange.modal.passwordMismatch')));
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t('passwordChange.modal.confirmPassword')} />
            </Form.Item>
            <div>
              <Button style={{ width: '100%', justifyContent: 'center' }} className="btn-secondary" htmlType="submit">
                {t('passwordChange.modal.submit')}
              </Button>
            </div>
          </Form>
        </LoadingSpinner>
      )}
    </Modal>
  );
};

export default PasswordChangeModal;
