import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useCurrentLanguage from './hooks/user/useCurrentLanguage';
import { languages } from 'i18n';
import useAuthCheck from './hooks/auth/useAuthCheck';

import { ConfigProvider } from 'antd';
import AppLayout from './components/Layout';
import Router from './components/Router';
import { init, RouteTracker } from 'utils/general/GoogleAnalytics';
import { initializeAmplitude, logPageView, setUserProperties, trackEvent } from './utils/general/amplitudeService';
import { getEventData } from './utils/general/eventUtils';
import { setInitialState } from './store/entities/user';
import { showErrorNotification } from './utils/notification';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const { isAuthenticated } = useSelector(state => state.user);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    initializeAmplitude();
  }, []);

  useEffect(() => {
    if (location.pathname === '/') document.title = 'KBS';
    const timer = setTimeout(() => {
      logPageView(location.pathname, window.location.href, location.search || 'N/A', lang, isAuthenticated);
    }, 10);

    return () => clearTimeout(timer);
  }, [location.pathname, location.search, lang, isAuthenticated]);

  useEffect(() => {
    setUserProperties({ language: lang, logged_in: isAuthenticated });
  }, [lang, isAuthenticated]);

  useAuthCheck();

  const handleClickEvent = useCallback(
    event => {
      const target = event.target;
      const eventData = getEventData(target, location, lang, isAuthenticated);
      if (eventData) trackEvent(eventData.name, eventData.properties);
    },
    [location, lang, isAuthenticated]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickEvent);
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, [handleClickEvent]);

  useEffect(() => {
    const sessionSyncChannel = new BroadcastChannel('session_sync_channel');

    const handleMessage = event => {
      if (document.hidden) {
        if (event.data.type === 'LOGIN') {
          window.location.reload();
        }
      }
      if (event.data.type === 'LOGOUT') {
        dispatch(setInitialState());
      }
      if (event.data.type === 'SESSION_END') {
        dispatch(setInitialState());
        showErrorNotification(t('session.disclaimer.text'), { duration: 300 });
      }
    };

    sessionSyncChannel.addEventListener('message', handleMessage);

    return () => {
      sessionSyncChannel.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <ConfigProvider locale={languages.find(({ codeShort }) => codeShort === lang)?.antdLocale}>
        <AppLayout>
          {init() && <RouteTracker />}
          <Router />
        </AppLayout>
      </ConfigProvider>
    </>
  );
};

export default App;
